<template>
  <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33 26.44V25.09C33 24.8248 32.8946 24.5705 32.7071 24.3829C32.5196 24.1954 32.2652 24.09 32 24.09C31.7348 24.09 31.4804 24.1954 31.2929 24.3829C31.1054 24.5705 31 24.8248 31 25.09V26.44C31 26.7052 31.1054 26.9596 31.2929 27.1471C31.4804 27.3347 31.7348 27.44 32 27.44C32.2652 27.44 32.5196 27.3347 32.7071 27.1471C32.8946 26.9596 33 26.7052 33 26.44Z"
      fill="white"
    />
    <path
      d="M60 16.29H50.76L53.56 13.48C53.6647 13.3904 53.7497 13.28 53.8098 13.156C53.8698 13.0319 53.9035 12.8968 53.9088 12.7591C53.9142 12.6213 53.8909 12.484 53.8407 12.3557C53.7904 12.2274 53.7141 12.1108 53.6167 12.0134C53.5192 11.9159 53.4027 11.8396 53.2744 11.7894C53.146 11.7391 53.0087 11.7159 52.871 11.7212C52.7333 11.7265 52.5981 11.7603 52.4741 11.8203C52.35 11.8803 52.2397 11.9653 52.15 12.07L47.64 16.57C47.5463 16.663 47.4719 16.7736 47.4211 16.8954C47.3704 17.0173 47.3442 17.148 47.3442 17.28C47.3442 17.412 47.3704 17.5427 47.4211 17.6646C47.4719 17.7864 47.5463 17.897 47.64 17.99V17.99L52.15 22.5C52.2427 22.5945 52.3531 22.6697 52.475 22.7212C52.5968 22.7727 52.7277 22.7995 52.86 22.8C53.1253 22.8 53.3796 22.6946 53.5671 22.5071C53.7547 22.3196 53.86 22.0652 53.86 21.8C53.8595 21.6677 53.8327 21.5368 53.7812 21.4149C53.7297 21.2931 53.6545 21.1826 53.56 21.09L50.76 18.29H60C60.2653 18.29 60.5196 18.1846 60.7071 17.9971C60.8947 17.8096 61 17.5552 61 17.29C61 17.0248 60.8947 16.7704 60.7071 16.5829C60.5196 16.3954 60.2653 16.29 60 16.29Z"
      fill="#6f00ff"
    />
    <path
      d="M10.44 21.09C10.2537 21.2774 10.1492 21.5308 10.1492 21.795C10.1492 22.0592 10.2537 22.3127 10.44 22.5C10.6246 22.6884 10.8762 22.7963 11.14 22.8C11.2723 22.7995 11.4032 22.7727 11.5251 22.7212C11.6469 22.6697 11.7574 22.5945 11.85 22.5L16.36 18C16.4537 17.9071 16.5281 17.7965 16.5789 17.6746C16.6297 17.5527 16.6558 17.422 16.6558 17.29C16.6558 17.158 16.6297 17.0273 16.5789 16.9055C16.5281 16.7836 16.4537 16.673 16.36 16.58V16.58L11.85 12.07C11.6626 11.8838 11.4092 11.7792 11.145 11.7792C10.8808 11.7792 10.6274 11.8838 10.44 12.07C10.2537 12.2574 10.1492 12.5108 10.1492 12.775C10.1492 13.0392 10.2537 13.2927 10.44 13.48L13.24 16.29H4C3.73478 16.29 3.48043 16.3954 3.29289 16.5829C3.10536 16.7705 3 17.0248 3 17.29C3 17.5552 3.10536 17.8096 3.29289 17.9971C3.48043 18.1847 3.73478 18.29 4 18.29H13.24L10.44 21.09Z"
      fill="#6f00ff"
    />
    <path
      d="M44.1799 14.44L45.3699 4.89002C45.387 4.75044 45.3745 4.60883 45.3331 4.47442C45.2918 4.34002 45.2225 4.21585 45.1299 4.11002C45.0362 4.00335 44.9209 3.91786 44.7915 3.85924C44.6622 3.80061 44.5219 3.7702 44.3799 3.77002H19.6199C19.4779 3.7702 19.3376 3.80061 19.2083 3.85924C19.079 3.91786 18.9636 4.00335 18.8699 4.11002C18.7773 4.21585 18.7081 4.34002 18.6667 4.47442C18.6254 4.60883 18.6128 4.75044 18.6299 4.89002L19.8199 14.44C20.118 17.0248 19.8522 19.6435 19.0409 22.1156C18.2295 24.5878 16.8918 26.8546 15.1199 28.76C12.2079 31.8716 10.3811 35.8419 9.91247 40.0777C9.44383 44.3135 10.3586 48.5872 12.5199 52.26L16.8899 59.73C16.9888 59.8727 17.1181 59.9915 17.2686 60.078C17.4191 60.1645 17.5869 60.2164 17.7599 60.23H46.2399C46.4161 60.2307 46.5894 60.1848 46.7422 60.097C46.8949 60.0092 47.0218 59.8826 47.1099 59.73L51.4799 52.26C53.6413 48.5872 54.556 44.3135 54.0874 40.0777C53.6187 35.8419 51.7919 31.8716 48.8799 28.76C47.1081 26.8546 45.7704 24.5878 44.959 22.1156C44.1476 19.6435 43.8818 17.0248 44.1799 14.44V14.44ZM21.7999 14.19L20.7499 5.77002H43.2499L42.2499 14.19C41.9271 16.8732 42.1581 19.5941 42.9287 22.1844C43.6993 24.7748 44.993 27.1796 46.7299 29.25C42.2837 31.8769 37.2141 33.2625 32.0499 33.2625C26.8857 33.2625 21.8161 31.8769 17.3699 29.25C19.098 27.1756 20.3827 24.769 21.1446 22.1788C21.9065 19.5886 22.1296 16.8698 21.7999 14.19V14.19ZM30.9999 58.19H18.3299L14.2499 51.19C13.0382 49.149 12.2538 46.8831 11.9444 44.5298C11.635 42.1765 11.807 39.7849 12.4499 37.5C23.0799 37.87 30.9999 45.76 30.9999 50.92V58.19ZM49.7499 51.19L45.6699 58.19H32.9999V50.92C32.9999 45.76 40.9199 37.87 51.5499 37.56C52.1929 39.8449 52.3649 42.2365 52.0555 44.5898C51.746 46.9431 50.9617 49.209 49.7499 51.25V51.19Z"
      fill="white"
    />
  </svg>
</template>
