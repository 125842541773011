<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 64 64"
    enable-background="new 0 0 64 64"
    xml:space="preserve"
  >
    <path
      d="M60,50h-1v-2c0-1.1-0.9-2-2-2h-2c0-1.1-0.9-2-2-2h-2c-1.1,0-2,0.9-2,2v4h-3V9c0-0.6-0.4-1-1-1h-4c0-1.1-0.9-2-2-2H28.9
	c-0.5-2.3-2.5-4-4.9-4s-4.4,1.7-4.9,4H9C7.9,6,7,6.9,7,8H3C2.4,8,2,8.4,2,9v52c0,0.6,0.4,1,1,1h24v-2H4V10h3v2c0,0.6,0.4,1,1,1h32
	c0.6,0,1-0.4,1-1v-2h3v40h-5v-4c0-1.1-0.9-2-2-2h-2c-1.1,0-2,0.9-2,2h-2c-1.1,0-2,0.9-2,2v2h-1c-1.1,0-2,0.9-2,2v2c0,1.1,0.9,2,2,2
	h1v2c0,1.1,0.9,2,2,2h2c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2v-4h5v4h-4v2h5c0.6,0,1-0.4,1-1v-5h3v4c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2
	h2c1.1,0,2-0.9,2-2v-2h1c1.1,0,2-0.9,2-2v-2C62,50.9,61.1,50,60,50z M39,11H9V9V8h11c0.6,0,1-0.4,1-1c0-1.7,1.3-3,3-3s3,1.3,3,3
	c0,0.6,0.4,1,1,1h11v1V11z M28,54v-2h1v2H28z M31,58V48h2v10H31z M37,60h-2v-2V48v-2h2v5v4V60z M45,54h-6v-2h6h4v2H45z M51,60v-5v-4
	v-5h2l0,2c0,0,0,0,0,0v10c0,0,0,0,0,0l0,2H51z M55,58V48h2l0,10H55z M60,54h-1v-2h1V54z"
      fill="currentColor"
    ></path>
    <path d="M6,15v42c0,0.6,0.4,1,1,1h18v-2H8V15H6z" fill="currentColor"></path>
    <rect x="40" y="15" width="2" height="28" fill="currentColor"></rect>
    <path
      d="M16,25c0.3,0,0.5-0.1,0.7-0.3l5-5l-1.4-1.4L16,22.6l-1.3-1.3l-1.4,1.4l2,2C15.5,24.9,15.7,25,16,25z"
      fill="#6f00ff"
    ></path>
    <path
      d="M15.3,32.7c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l5-5l-1.4-1.4L16,30.6l-1.3-1.3l-1.4,1.4L15.3,32.7z"
      fill="#6f00ff"
    ></path>
    <path
      d="M15.3,40.7c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l5-5l-1.4-1.4L16,38.6l-1.3-1.3l-1.4,1.4L15.3,40.7z"
      fill="#6f00ff"
    ></path>
    <path
      d="M15.3,48.7c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l5-5l-1.4-1.4L16,46.6l-1.3-1.3l-1.4,1.4L15.3,48.7z"
      fill="#6f00ff"
    ></path>
  </svg>
</template>
