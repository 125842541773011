<template>
  <div class="relative bg-white">
    <div class="lg:absolute lg:inset-0">
      <div class="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
        <img
          class="object-cover w-full h-56 lg:absolute lg:h-full"
          src="../../public/home_hero.jpeg"
          alt=""
        />
      </div>
    </div>
    <div
      class="relative px-4 pt-12 pb-16 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
    >
      <div class="lg:col-start-2 lg:pl-8">
        <div
          class="mx-auto text-base max-w-prose lg:max-w-lg lg:ml-auto lg:mr-0"
        >
          <h2
            class="font-semibold leading-6 tracking-wide uppercase text-fpurple"
          >
            ¡Ponte en forma con nuestra ayuda!
          </h2>
          <h3
            class="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-fgreen sm:text-4xl"
          >
            No esperes
          </h3>
          <p class="mt-8 text-lg text-gray-500">
            Olvídate del lápiz y el papel
          </p>
          <div class="mt-5 prose text-gray-500 prose-indigo">
            <p>
              Con nuestra página puedes acceder en cualquier momento y en
              cualquier lugar a tus rutinas
            </p>
            <p>
              Tambien puedes modificarlas en caso de que un día decidas
              cambiarlas
            </p>
          </div>
          <p class="mt-8 text-lg text-gray-500">Sin límites</p>
          <div class="mt-5 prose text-gray-500 prose-indigo">
            <p>No existen límites de creación de tus rutinas personalizadas</p>
            <p>¡Tambien puedes guardarte cuantas rutinas quieras!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>
