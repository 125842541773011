<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
    <path
      d="M60.67,45.48A4.45,4.45,0,0,0,54.82,43L42.09,47.51a4.9,4.9,0,0,0,.07-.77,4.28,4.28,0,0,0-4.28-4.28h-3.3a18.07,18.07,0,0,1-8.42-2.09,20,20,0,0,0-9.35-2.32h-6a1,1,0,0,0,0,2h6a17.88,17.88,0,0,1,8.41,2.09,20.11,20.11,0,0,0,9.36,2.32h3.3a2.28,2.28,0,0,1,2.28,2.29,2.22,2.22,0,0,1-.79,1.71l-1.68.59H26.78a1,1,0,0,0,0,2h11.1a1,1,0,0,0,.41-.09l1.9-.67h0l15.33-5.39a2.46,2.46,0,0,1,3.27,1.34,2.47,2.47,0,0,1-1.09,3.1L39.36,57.17a22.36,22.36,0,0,1-8,1.81,22.76,22.76,0,0,1-8.5-1.27L17.74,55.9a20.69,20.69,0,0,0-6.93-1.19,1,1,0,1,0,0,2,18.86,18.86,0,0,1,6.27,1.07l5.14,1.82a24.71,24.71,0,0,0,8.2,1.4l1,0a24.77,24.77,0,0,0,8.69-2l18.43-7.87A4.47,4.47,0,0,0,60.67,45.48Z"
      fill="currentColor"
    />
    <path
      d="M8.58,33.5H6.22A3.23,3.23,0,0,0,3,36.73V56.78A3.23,3.23,0,0,0,6.22,60H8.58a3.23,3.23,0,0,0,3.23-3.23V36.73A3.23,3.23,0,0,0,8.58,33.5ZM9.81,56.78A1.23,1.23,0,0,1,8.58,58H6.22A1.23,1.23,0,0,1,5,56.78V36.73A1.23,1.23,0,0,1,6.22,35.5H8.58a1.23,1.23,0,0,1,1.23,1.23Z"
      fill="currentColor"
    />
    <path
      d="M58.19,8.9a9.4,9.4,0,0,0-6.73-2.81h0A9.54,9.54,0,0,0,44.68,8.9l-2.86,2.86L39,8.9a9.55,9.55,0,1,0-13.5,13.51L41.11,38.07a1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3L58.19,22.41a9.52,9.52,0,0,0,0-13.51ZM56.78,21l-15,15-15-15A7.55,7.55,0,1,1,37.54,10.31l3.57,3.57a1,1,0,0,0,1.42,0l3.57-3.57a7.52,7.52,0,0,1,5.35-2.22h0a7.45,7.45,0,0,1,5.32,2.22,7.53,7.53,0,0,1,0,10.68Z"
      fill="currentColor"
    />
    <path
      d="M36,26.36h-.08a1,1,0,0,1-.78-.49l-4.37-7.36-6.5,5.32a1,1,0,0,1-.63.23H19.12a1,1,0,0,1,0-2h4.16l7.12-5.84a1,1,0,0,1,1.49.27l4.25,7.14,4.91-5.94a1,1,0,0,1,.77-.36h8.24a1,1,0,0,1,0,2H42.29L36.77,26A1,1,0,0,1,36,26.36Z"
      fill="#6f00ff"
    />
  </svg>
</template>
