<template>
  <svg
    viewBox="0 0 55 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke-width="2"
  >
    <g clip-path="url(#clip0_8233_24727)">
      <path
        d="M17.0238 27.429H37.126C41.5634 27.429 45.1651 23.9479 45.1651 19.6742C45.1651 15.4004 41.5548 11.9194 37.126 11.9194H17.515C17.0238 9.02426 14.2149 7.10277 11.3198 7.7318C8.72621 8.29184 6.96846 10.7734 7.2873 13.4014C7.64919 16.3483 10.3892 18.3645 13.2843 17.882C15.4557 17.5115 17.1531 15.8141 17.5236 13.6427H37.1346C40.6156 13.6427 43.4505 16.3483 43.4505 19.6742C43.4505 23.0002 40.6157 25.7058 37.1346 25.7058H17.0238C12.5864 25.7058 8.97606 29.1868 8.97606 33.4606C8.97606 37.7343 12.5863 41.2153 17.0152 41.2153H31.0169L27.3291 44.9118C26.9844 45.2392 26.9758 45.7907 27.3119 46.1267C27.6479 46.4627 28.1907 46.48 28.5268 46.1439C28.5354 46.1353 28.544 46.1267 28.544 46.1267L33.7052 40.9655C34.0412 40.6295 34.0412 40.0866 33.7052 39.7506L28.544 34.5807C28.1993 34.2533 27.6565 34.2619 27.3291 34.5979C27.0102 34.934 27.0102 35.4596 27.3291 35.7956L31.0255 39.4921H17.0238C13.5342 39.4921 10.6994 36.7866 10.6994 33.4606C10.6994 30.1346 13.5342 27.429 17.0238 27.429ZM12.4227 16.219C10.5185 16.219 8.97606 14.6767 8.97606 12.7724C8.97606 10.8682 10.5184 9.32581 12.4227 9.32581C14.3269 9.32581 15.8693 10.8681 15.8693 12.7724C15.8693 14.6766 14.3269 16.219 12.4227 16.219Z"
        stroke="currentColor"
      />
      <path
        d="M42.5803 35.1753C39.7282 35.1753 37.4104 37.4931 37.4104 40.3452C37.4104 43.1972 39.7282 45.515 42.5803 45.515C45.4323 45.515 47.7501 43.1972 47.7501 40.3452C47.7501 37.4931 45.441 35.1839 42.5803 35.1753ZM42.5803 43.7917C40.6761 43.7917 39.1337 42.2494 39.1337 40.3452C39.1337 38.4409 40.676 36.8986 42.5803 36.8986C44.4845 36.8986 46.0269 38.4409 46.0269 40.3452C46.0269 42.2494 44.4845 43.7917 42.5803 43.7917Z"
        stroke="#6f00ff"
      />
    </g>
    <defs>
      <clipPath id="clip0_8233_24727">
        <rect
          width="40.5"
          height="40.5"
          fill="white"
          transform="translate(7.25 6.75)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
